/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useState, useEffect, useRef, memo } from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  chakra,
  Box,
  Spinner,
} from '@chakra-ui/react'
import memoize from 'memoize-one'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons'
import { useOnClickOutside } from 'usehooks-ts'
import { FixedSizeList, areEqual } from 'react-window'
import {
  useTable,
  useSortBy,
  Column,
  useFlexLayout,
  usePagination,
} from 'react-table'
import { useQueryState } from 'next-usequerystate'

import { useRouter } from 'next/router'
import { useIncidentsFilters } from '@/hooks/useIncidentsFilters'
import {
  openIncidentDrawer,
  selectIncidentsPageSize,
  selectIsIncidentDrawerOpen,
  setIncident,
  setIncidentsPageSize,
} from '@/redux/ui/uiSlice'

import { getOrderBy, getSortedColumn } from '../utils/query'
import { RefetchIncidentsFnType } from '../types/types'
import { mixpanel } from '@/utils/analytics'
import { IncidentStatus } from '@/graphql/generated/schemas'
import { Navigation, PageSizeSelector } from '@/components/ui/TableUtils'
import { getCurrentRangeMessage } from '@/utils/pageNavigation'

const createItemData = memoize((items, handleSelectItem) => ({
  items,
  handleSelectItem,
}))

export type DataTableProps<Data extends unknown> = {
  data: Data[]
  columns: Column<Data>[]
  refetch?: RefetchIncidentsFnType
  totalIncidents?: number
}

const ROW_HEIGHT = 40

export function DataTable<Data extends unknown>({
  data,
  columns,
  refetch,
  totalIncidents,
}: DataTableProps<Data>) {
  const [, setDisplayId] = useQueryState('displayId')
  const { query } = useRouter()
  const ref = useRef(null)

  const dispatch = useDispatch()
  const isIncidentDrawerOpen = useSelector(selectIsIncidentDrawerOpen)
  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 80, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  )
  const [selectedId, setSelectedId] = useState(null)

  const { filters } = useIncidentsFilters()
  const initialPageSize = useSelector(selectIncidentsPageSize)
  const skipPageResetRef = useRef(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    getTableProps,
    headerGroups,
    rows,
    columns: tableColumns,
    prepareRow,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      autoResetPage: !skipPageResetRef.current,
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: initialPageSize,
        sortBy: [
          {
            id: 'date',
            desc: true,
          },
        ],
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: true,
      disableSortRemove: true,
      pageCount: -1,
    },
    useFlexLayout,
    useSortBy,
    usePagination
  )
  const pageCount = Math.ceil(totalIncidents / pageSize)
  const sortedColumn = getSortedColumn(tableColumns)

  useEffect(() => {
    skipPageResetRef.current = true
    setIsLoading(true)
    refetch({
      filter: filters,
      first: pageIndex,
      last: pageSize,
      orderBy: getOrderBy(sortedColumn),
    }).then(() => {
      setIsLoading(false)
      skipPageResetRef.current = false
    })
  }, [pageIndex, pageSize, JSON.stringify(sortedColumn)])

  useEffect(() => {
    gotoPage(0)
    skipPageResetRef.current = true
    setIsLoading(true)
    refetch({
      filter: filters,
      first: pageIndex,
      last: pageSize,
      orderBy: getOrderBy(sortedColumn),
    }).then(() => {
      setIsLoading(false)
      skipPageResetRef.current = false
    })
  }, [JSON.stringify(filters)])

  const incidentId = rows.find(
    (row) => row.original.displayId === query.displayId
  )?.original.id
  const incidentIndex = rows.findIndex((row) => row.original.id === incidentId)

  const openAlertIncidentDrawer = (id: string) => {
    dispatch(setIncident({ id }))
    dispatch(openIncidentDrawer())
  }
  const handleSelectItem = (
    id: string,
    displayId: string,
    incidentStatus: IncidentStatus,
    incidentType: string
  ) => {
    setSelectedId(displayId)
    openAlertIncidentDrawer(id)
    setDisplayId(displayId)
    mixpanel.track('Button Press: Incident Row, Incidents View', {
      incident_id: displayId,
      incident_status: incidentStatus,
      incident_type: incidentType,
    })
  }

  const headerClicked = (column) => {
    mixpanel.track('Sorted: Incidents View', {
      sorted_by: column.Header,
    })
  }

  useOnClickOutside(ref, () => {
    if (!isIncidentDrawerOpen && selectedId) {
      setSelectedId(null)
      setDisplayId(null)
    }
  })

  useEffect(() => {
    if (query.displayId && incidentIndex !== -1) {
      setSelectedId(query.displayId)
      openAlertIncidentDrawer(incidentId)
    }
  }, [])

  const itemData = createItemData(rows, handleSelectItem)

  const RenderRow = memo<{ data: any; index: number; style: any }>(
    ({ data, index, style }) => {
      const { items, handleSelectItem } = data
      const row = items[index]
      prepareRow(row)
      const isSelected = selectedId === row.original.displayId
      return (
        <Tr
          {...row.getRowProps({
            style,
          })}
          data-testid='incidentsPage_table_row'
          cursor='pointer'
          h='40px'
          transition='ease-in-out'
          transitionDuration='300ms'
          transitionProperty='all'
          bgColor={isSelected ? '#6096f5' : '#fff'}
          _hover={{
            bgColor: isSelected ? '#4284f8' : 'gray.100',
          }}
          onClick={() =>
            handleSelectItem(
              row.original.id,
              row.original.displayId,
              row.original.incidentStatus,
              row.original.incidentType
            )
          }
        >
          {row.cells.map((cell) => (
            <Td
              {...cell.getCellProps()}
              pos='relative'
              px='4'
              py='0'
              flexDirection='row'
              alignItems='center'
              fontSize='14px'
              letterSpacing='-0.53px'
              color={isSelected ? '#fff' : '#2D2E41'}
              fontWeight='medium'
              isNumeric={cell.column.isNumeric}
              borderColor='#D5DCE4'
            >
              <Box pos='relative' top='50%' transform='translateY(-50%)'>
                {cell.render('Cell')}
              </Box>
            </Td>
          ))}
        </Tr>
      )
    },
    areEqual
  )
  return (
    <Box w={{ base: '1000px', lg: 'full' }} mb='50px'>
      <Table
        {...getTableProps()}
        bgColor='white'
        rounded={{ base: 'none', lg: 'lg' }}
        shadow='0px 2px 5px 6px rgba(0, 0, 0, 0.1)'
        border='none'
        borderWidth='3px'
        data-testid='incidentsPage_table'
      >
        <Thead
          bgColor='#F2F4F9'
          overflowY='auto'
          overflowX='hidden'
          data-testid='incidentsPage_table_header'
        >
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps()}
                  d='flex'
                  alignItems='center'
                  isNumeric={column?.isNumeric}
                  textTransform='capitalize'
                  fontWeight='semibold'
                  fontSize='14px'
                  color='#2D2E41'
                  letterSpacing='-1px'
                  px='4'
                  borderColor='#D5DCE4'
                  onClick={() => headerClicked(column)}
                >
                  <Box d='inline-block' {...column?.getSortByToggleProps()}>
                    {column.render('Header')}
                  </Box>
                  <chakra.span>
                    {column?.isSorted ? (
                      column?.isSortedDesc ? (
                        <ArrowDownIcon aria-label='sorted descending' />
                      ) : (
                        <ArrowUpIcon aria-label='sorted ascending' />
                      )
                    ) : null}
                  </chakra.span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody ref={ref}>
          {isLoading ? (
            <Box
              as='tr'
              height={data.length * ROW_HEIGHT}
              display='grid'
              placeItems={'center'}
            >
              <Box as='td'>
                <Spinner
                  thickness='4px'
                  emptyColor='gray.300'
                  color='#298bbd'
                  size='md'
                />
              </Box>
            </Box>
          ) : (
            <FixedSizeList
              height={data.length * ROW_HEIGHT}
              itemCount={rows.length}
              itemData={itemData}
              itemSize={ROW_HEIGHT}
            >
              {RenderRow}
            </FixedSizeList>
          )}
        </Tbody>
        <Tfoot bgColor='#F2F4F9'>
          <Tr>
            <Td
              display='grid'
              gridTemplateColumns='repeat(3, 1fr)'
              alignItems='center'
              textTransform='none'
              fontWeight='semibold'
              fontSize='14px'
              color='#2D2E41'
              letterSpacing='-1px'
            >
              <Box as='p' data-testid='incidentsPage_table_currentRange'>
                {getCurrentRangeMessage(pageIndex, pageSize, totalIncidents)}
              </Box>
              <Navigation
                pageIndex={pageIndex}
                pageCount={pageCount}
                previousPage={previousPage}
                nextPage={nextPage}
                gotoPage={gotoPage}
                dataTestId='incidentsPage_table_navigation'
              />
              <PageSizeSelector
                title='Incidents per page:'
                onClick={(value) => dispatch(setIncidentsPageSize(value))}
                pageSize={pageSize}
                setPageSize={setPageSize}
                dataTestId='incidentsPage_table_pageSize'
              />
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </Box>
  )
}
