import { Flex, Box } from '@chakra-ui/react'
import Select, { components } from 'react-select'
import styled from '@emotion/styled'
import makeAnimated from 'react-select/animated'
import chroma from 'chroma-js'

export const StyledSelect = styled(Select)`
  .react-select__control {
    border: 2px solid
      ${(p) => (p.valid ? '#2BD2B4' : p.isInvalid ? '#D01030' : '#9FB8E5')};
    min-height: 48px;
    border-radius: 6px;
  }
  .react-select__control--is-focused {
    box-shadow: 0 0 0 2px rgba(44, 133, 244, 0.6);
  }
  .react-select__value-container {
    font-weight: 600;
    font-size: 14px;
    padding-left: 16px;
  }
  .react-select__placeholder {
    font-size: 14px;
    color: rgba(45, 46, 65, 0.3);
    font-style: italic;
    font-weight: 500;
  }
  .react-select__single-value {
    overflow: visible;
    padding: 0px 8px;
  }
  .react-select__multi-value {
    border-radius: 4px;
  }
  .react-select__option {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.4px;
  }
  .react-select__indicator-separator {
    background-color: #9fb8e5;
  }
`
export interface InputSelectIProps {
  options: {
    value: string
    label: string
  }[]
  [key: string]: unknown
}

const animatedComponents = makeAnimated()

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled = false, isFocused, isSelected }) => {
    if (!data?.color) {
      return styles
    }
    const color = chroma(data.color)
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    }
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles, { data }) => {
    if (!data?.color) {
      return styles
    }
    return {
      ...styles,
      color: chroma.contrast(data.color, 'white') > 2 ? 'white' : 'black',
      ':before': {
        content: '" "',
        position: 'absolute',
        height: 'calc(100% + 10px)',
        width: 'calc(100% + 10px)',
        zIndex: '-1',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        borderRadius: '5px',
        backgroundColor: data.color,
      },
    }
  },
  multiValue: (styles, { data }) => {
    if (!data?.color) {
      return styles
    }
    const color = chroma(data.color)
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    }
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
}

const formatGroupLabel = (data: { label: string; options: [] }) => (
  <Flex alignItems='center' justifyContent='space-between' py='1'>
    <Box as='span' d='flex' alignItems='center'>
      <Box
        as='span'
        fontSize='15px'
        textTransform='capitalize'
        letterSpacing='-0.53px'
        fontWeight='bold'
      >
        {data.label}
      </Box>
    </Box>
    <Box
      d='inline-block'
      fontSize='14px'
      lineHeight='1'
      minH='1'
      p='0.16666666666667em 0.5em'
      bgColor='#EBECF0'
      borderRadius='2em'
      color='#2D2E41'
    >
      {data.options.length}
    </Box>
  </Flex>
)

export default function InputSelect({ options, ...rest }: InputSelectIProps) {
  return (
    <StyledSelect
      {...rest}
      className='input-select-container'
      classNamePrefix='react-select'
      options={options}
      formatGroupLabel={formatGroupLabel}
      styles={{
        ...colourStyles,
        menu: (base) => ({
          ...base,
          zIndex: 9999,
        }),
      }}
      components={{
        IndicatorSeparator: null,
        ...animatedComponents,
        Option,
      }}
    />
  )
}

const Option = (props) => (
  <components.Option
    {...props}
    innerProps={{ ...props.innerProps, 'aria-label': props?.label }}
  />
)
