import { ButtonGroup, Button } from '@chakra-ui/react'

interface ToggleButtonIProps {
  isFull?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  bgColor: string
  textColor: string
  borderColor: string
  ariaLabel: string
  handleOnClick: () => void
  children: string
  dataTestId?: string
}

export interface ToggleButtonSwitchIProps {
  size?: 'xs' | 'sm' | 'md' | 'lg'
  isFull?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  isLeftActive: boolean
  onLeftButtonClick: () => void
  onRightButtonClick: () => void
  activeBgColor: string
  activeTextColor: string
  inactiveBgColor?: string
  inactiveTextColor?: string
  leftButtonText: string
  rightButtonText: string
  leftArialLabel: string
  rightArialLabel: string
  height?: string
  leftButtonDataTestId?: string
  rightButtonDataTestId?: string
  dataTestId?: string
}

function ToggleButton({
  isFull = false,
  isLoading = false,
  isDisabled,
  bgColor,
  textColor,
  borderColor,
  ariaLabel,
  handleOnClick,
  children,
  dataTestId,
}: ToggleButtonIProps) {
  return (
    <Button
      h='auto'
      minH='8'
      w={isFull ? 'full' : ''}
      isLoading={isLoading}
      isDisabled={isDisabled}
      fontWeight='500'
      fontSize='12px'
      borderWidth='2px'
      letterSpacing='-0.4px'
      bgColor={bgColor}
      borderColor={borderColor}
      textColor={textColor}
      aria-label={ariaLabel}
      data-testid={dataTestId}
      onClick={handleOnClick}
      _hover={{
        bg: '',
      }}
      _focus={{
        boxShadow: '',
      }}
      _active={{
        _disabled: {
          bg: bgColor,
        },
      }}
      _disabled={{
        bg: bgColor,
      }}
    >
      {children}
    </Button>
  )
}

export default function ToggleButtonSwitch({
  size = 'sm',
  isFull = false,
  isLoading = false,
  isDisabled = false,
  isLeftActive,
  onLeftButtonClick,
  onRightButtonClick,
  activeBgColor,
  activeTextColor,
  inactiveBgColor = 'white',
  inactiveTextColor = 'black',
  leftButtonText,
  rightButtonText,
  leftArialLabel,
  rightArialLabel,
  height = 'auto',
  leftButtonDataTestId,
  rightButtonDataTestId,
  dataTestId,
}: ToggleButtonSwitchIProps) {
  const isRightActive = !isLeftActive
  return (
    <ButtonGroup
      data-testid={dataTestId}
      size={size}
      w={isFull ? 'full' : ''}
      isAttached
      variant='outline'
      h={height}
    >
      <ToggleButton
        isFull={isFull}
        isLoading={isLoading}
        isDisabled={isLeftActive || isDisabled}
        bgColor={isLeftActive ? activeBgColor : inactiveBgColor}
        textColor={isLeftActive ? activeTextColor : inactiveTextColor}
        borderColor={isLeftActive ? activeBgColor : 'gray.200'}
        ariaLabel={leftArialLabel}
        handleOnClick={onLeftButtonClick}
        dataTestId={leftButtonDataTestId}
      >
        {leftButtonText}
      </ToggleButton>
      <ToggleButton
        isFull={isFull}
        isLoading={isLoading}
        isDisabled={isRightActive || isDisabled}
        bgColor={isRightActive ? activeBgColor : inactiveBgColor}
        textColor={isRightActive ? activeTextColor : inactiveTextColor}
        borderColor={isRightActive ? activeBgColor : 'gray.200'}
        ariaLabel={rightArialLabel}
        handleOnClick={onRightButtonClick}
        dataTestId={rightButtonDataTestId}
      >
        {rightButtonText}
      </ToggleButton>
    </ButtonGroup>
  )
}
