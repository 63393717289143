// eslint-disable-next-line no-restricted-imports
import { incidentStatusOptions } from '@/features/incidents-table/incidents-filter-drawer/components/IncidentsFilterDrawer'
import { DeviceType } from '@/graphql/generated/schemas'
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import type { AppState } from '../store'
import type {
  Filter,
  MultiFilter,
  CreatedAtFilter,
  HasActiveIncidentsFilter,
  FloorTabCounts,
  DevicePayload,
  EmergencyCallPayload,
} from './uiSlice.types'

export type FacilityDrawer = 'incidents' | 'devices' | 'floors'

export interface UIState {
  sideNavOpen: boolean
  editMode: boolean
  confirmEditMode: boolean
  facility: {
    drawerOpen?: FacilityDrawer
    placedDeviceCount?: number
    incidentsCount?: number
    floorCount?: number
  }
  facilities: {
    filters: {
      ids: MultiFilter
      createdAtRange: CreatedAtFilter
      hasIncidents: HasActiveIncidentsFilter
    }
  }
  devices: {
    filters: {
      facilityIds: MultiFilter
      floorIds: MultiFilter
      createdAtRange: CreatedAtFilter
      hasIncidents: HasActiveIncidentsFilter
      deviceStatus: MultiFilter
      deviceType: MultiFilter
    }
  }
  incidents: {
    filters: {
      nameLike: Filter
      ownerId: Filter
      facilityIds: MultiFilter
      floorIds: MultiFilter
      createdAtRange: CreatedAtFilter
      incidentsStatus: MultiFilter
      deviceType: Filter
      priorityStatus: MultiFilter
    }
    pageSize: number
  }
  outstandingIncidents: {
    pageSize: number
  }
  notifications: {
    showUnread: boolean
  }
  sops: {
    filters: {
      nameLike: Filter
      facilityId: Filter
      createdAtRange: CreatedAtFilter
      creatorId: Filter
    }
    pageSize: number
  }
  drawers: {
    incident: {
      id: string
      isOpen: boolean
    }
    device: {
      id: string
      type: DeviceType
      isOpen: boolean
    }
    sop: {
      id: string
      isOpen: boolean
    }
    sopIncident: {
      id: string | null
      isOpen: boolean
      sopNameLike: string
    }
  }
  emergencyCall: {
    isOpen: boolean
    isExpanded: boolean
    isMuted: boolean
    incident: {
      id: string
      displayId: string
      facilityName: string
    }
    filters: {
      displayIdLike: Filter
    }
    pageSize: number
  }
}

const initialState: UIState = {
  sideNavOpen: false,
  editMode: false,
  confirmEditMode: false,
  facility: {
    drawerOpen: 'devices',
    placedDeviceCount: null,
    incidentsCount: null,
    floorCount: null,
  },
  facilities: {
    filters: {
      ids: null,
      createdAtRange: null,
      hasIncidents: null,
    },
  },
  devices: {
    filters: {
      facilityIds: null,
      floorIds: null,
      createdAtRange: null,
      hasIncidents: null,
      deviceStatus: null,
      deviceType: null,
    },
  },
  incidents: {
    filters: {
      nameLike: null,
      ownerId: null,
      facilityIds: null,
      floorIds: null,
      createdAtRange: null,
      incidentsStatus: [incidentStatusOptions[0], incidentStatusOptions[1]],
      deviceType: null,
      priorityStatus: null,
    },
    pageSize: 25,
  },
  outstandingIncidents: {
    pageSize: 25,
  },
  notifications: {
    showUnread: false,
  },
  sops: {
    filters: {
      nameLike: null,
      facilityId: null,
      createdAtRange: null,
      creatorId: null,
    },
    pageSize: 25,
  },
  drawers: {
    incident: {
      id: null,
      isOpen: false,
    },
    device: {
      id: null,
      type: null,
      isOpen: false,
    },
    sop: {
      id: null,
      isOpen: false,
    },
    sopIncident: {
      id: null,
      isOpen: false,
      sopNameLike: null,
    },
  },
  emergencyCall: {
    isOpen: false,
    isExpanded: true,
    isMuted: false,
    incident: {
      id: null,
      displayId: null,
      facilityName: null,
    },
    filters: {
      displayIdLike: null,
    },
    pageSize: 25,
  },
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    resetUi: (state) => {
      return {
        ...initialState,
        confirmEditMode: state.confirmEditMode,
      }
    },
    toggleSideNavOpen: (state) => {
      state.sideNavOpen = !state.sideNavOpen
    },
    toggleEditMode: (state) => {
      state.editMode = !state.editMode
    },
    toggleNotificationsUnread: (state) => {
      state.notifications.showUnread = !state.notifications.showUnread
    },
    confirmEditMode: (state) => {
      state.editMode = true
      state.confirmEditMode = true
    },
    openFacilityFloorsDrawer: (state) => {
      state.facility.drawerOpen = 'floors'
    },
    openFacilityIncidentsDrawer: (state) => {
      state.facility.drawerOpen = 'incidents'
    },
    openFacilityDevicesDrawer: (state) => {
      state.facility.drawerOpen = 'devices'
    },
    setFacilityTabBarCount: (state, action: PayloadAction<FloorTabCounts>) => {
      state.facility = {
        ...state.facility,
        ...action.payload,
      }
    },
    setFacilityIdsFilter: (state, action: PayloadAction<MultiFilter>) => {
      state.facilities.filters.ids = action.payload
    },
    setFacilityCreatedAtFilter: (
      state,
      action: PayloadAction<CreatedAtFilter>
    ) => {
      state.facilities.filters.createdAtRange = action.payload
    },
    setFacilityHasIncidentsFilter: (
      state,
      action: PayloadAction<HasActiveIncidentsFilter>
    ) => {
      state.facilities.filters.hasIncidents = action.payload
    },
    setDeviceFacilityIdsFilter: (state, action: PayloadAction<MultiFilter>) => {
      state.devices.filters.facilityIds = action.payload
    },
    setDeviceFloorsIdsFilter: (state, action: PayloadAction<MultiFilter>) => {
      state.devices.filters.floorIds = action.payload
    },
    setDeviceFacilityCreatedAtFilter: (
      state,
      action: PayloadAction<CreatedAtFilter>
    ) => {
      state.devices.filters.createdAtRange = action.payload
    },
    setDevicesHasIncidentsFilter: (
      state,
      action: PayloadAction<HasActiveIncidentsFilter>
    ) => {
      state.devices.filters.hasIncidents = action.payload
    },
    setDeviceStatusFilter: (state, action: PayloadAction<MultiFilter>) => {
      state.devices.filters.deviceStatus = action.payload
    },
    setDeviceTypeFilter: (state, action: PayloadAction<MultiFilter>) => {
      state.devices.filters.deviceType = action.payload
    },
    setIncidentNameLikeFilter: (state, action: PayloadAction<Filter>) => {
      state.incidents.filters.nameLike = action.payload
    },
    setIncidentOwnerIdFilter: (state, action: PayloadAction<Filter>) => {
      state.incidents.filters.ownerId = action.payload
    },
    setIncidentFacilityIdsFilter: (
      state,
      action: PayloadAction<MultiFilter>
    ) => {
      state.incidents.filters.facilityIds = action.payload
    },
    setIncidentFloorsIdsFilter: (state, action: PayloadAction<MultiFilter>) => {
      state.incidents.filters.floorIds = action.payload
    },
    setIncidentCreatedAtFilter: (
      state,
      action: PayloadAction<CreatedAtFilter>
    ) => {
      state.incidents.filters.createdAtRange = action.payload
    },
    setIncidentStatusFilter: (state, action: PayloadAction<MultiFilter>) => {
      state.incidents.filters.incidentsStatus = action.payload
    },
    setIncidentDeviceTypeFilter: (state, action: PayloadAction<Filter>) => {
      state.incidents.filters.deviceType = action.payload
    },
    setIncidentPriorityFilter: (state, action: PayloadAction<MultiFilter>) => {
      state.incidents.filters.priorityStatus = action.payload
    },
    setIncidentsPageSize: (state, action: PayloadAction<number>) => {
      state.incidents.pageSize = action.payload
    },
    setIncident: (state, action: PayloadAction<{ id: string }>) => {
      state.drawers.incident.id = action.payload.id
    },
    setOutstandingIncidentsPageSize: (state, action: PayloadAction<number>) => {
      state.outstandingIncidents.pageSize = action.payload
    },
    setSOPNameLikeFilter: (state, action: PayloadAction<Filter>) => {
      state.sops.filters.nameLike = action.payload
    },
    setSOPFacilityIdFilter: (state, action: PayloadAction<Filter>) => {
      state.sops.filters.facilityId = action.payload
    },
    setSOPCreatedAtFilter: (state, action: PayloadAction<CreatedAtFilter>) => {
      state.sops.filters.createdAtRange = action.payload
    },
    setSOPCreatorIdFilter: (state, action: PayloadAction<Filter>) => {
      state.sops.filters.creatorId = action.payload
    },
    setSOPPageSize: (state, action: PayloadAction<number>) => {
      state.sops.pageSize = action.payload
    },
    setDevice: (state, action: PayloadAction<DevicePayload>) => {
      state.drawers.device.id = action.payload.id
      state.drawers.device.type = action.payload.type
    },
    setSOP: (state, action: PayloadAction<{ id: string }>) => {
      state.drawers.sop.id = action.payload.id
    },
    openIncidentDrawer: (state) => {
      state.drawers.incident.isOpen = true
    },
    closeIncidentDrawer: (state) => {
      state.drawers.incident.isOpen = false
    },
    openDeviceDrawer: (state) => {
      state.drawers.device.isOpen = true
    },
    closeDeviceDrawer: (state) => {
      state.drawers.device.isOpen = false
    },
    openSOPDrawer: (state) => {
      state.drawers.sop.isOpen = true
    },
    closeSOPDrawer: (state) => {
      state.drawers.sop.isOpen = false
    },
    openSOPIncidentDrawer: (state) => {
      state.drawers.sopIncident.isOpen = true
    },
    closeSOPIncidentDrawer: (state) => {
      state.drawers.sopIncident.isOpen = false
    },
    toggleSOPIncidentDrawer: (state) => {
      state.drawers.sopIncident.isOpen = !state.drawers.sopIncident.isOpen
    },
    setSOPIncidentId: (state, action: PayloadAction<{ id: string }>) => {
      state.drawers.sopIncident.id = action.payload.id
    },
    setSOPIncidentNameLike: (state, action: PayloadAction<string>) => {
      state.drawers.sopIncident.sopNameLike = action.payload
    },
    expandEmergencyCall: (state) => {
      state.emergencyCall.isExpanded = true
    },
    minimizeEmergencyCall: (state) => {
      state.emergencyCall.isExpanded = false
    },
    muteEmergencyCall: (state) => {
      state.emergencyCall.isMuted = true
    },
    unmuteEmergencyCall: (state) => {
      state.emergencyCall.isMuted = false
    },
    toggleMuteEmergencyCall: (state) => {
      state.emergencyCall.isMuted = !state.emergencyCall.isMuted
    },
    openEmergencyCall: (state, action: PayloadAction<EmergencyCallPayload>) => {
      state.emergencyCall.isOpen = true
      state.emergencyCall.isExpanded = true
      state.emergencyCall.isMuted = false
      // Set incident details
      state.emergencyCall.incident.id = action.payload?.incidentId
      state.emergencyCall.incident.displayId = action.payload?.incidentDisplayId
      state.emergencyCall.incident.facilityName = action.payload?.facilityName
    },
    closeEmergencyCall: (state) => {
      state.emergencyCall.isOpen = false
      state.emergencyCall.incident.id = null
      state.emergencyCall.incident.displayId = null
      state.emergencyCall.incident.facilityName = null
    },
    setEmergencyCallDisplayIdLikeFilter: (
      state,
      action: PayloadAction<Filter>
    ) => {
      state.emergencyCall.filters.displayIdLike = action.payload
    },
    setEmergencyCallPageSize: (state, action: PayloadAction<number>) => {
      state.emergencyCall.pageSize = action.payload
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      console.log('HYDRATE', state, action.payload)
      return {
        ...state,
        ...action.payload.subject,
      }
    },
  },
})

export const {
  resetUi,
  toggleSideNavOpen,
  toggleNotificationsUnread,
  confirmEditMode,
  openFacilityFloorsDrawer,
  openFacilityIncidentsDrawer,
  openFacilityDevicesDrawer,
  setFacilityTabBarCount,
  toggleEditMode,
  setFacilityIdsFilter,
  setFacilityCreatedAtFilter,
  setFacilityHasIncidentsFilter,
  setDeviceFacilityIdsFilter,
  setDeviceFloorsIdsFilter,
  setDeviceFacilityCreatedAtFilter,
  setDevicesHasIncidentsFilter,
  setDeviceStatusFilter,
  setDeviceTypeFilter,
  setIncidentNameLikeFilter,
  setIncidentOwnerIdFilter,
  setIncidentFacilityIdsFilter,
  setIncidentFloorsIdsFilter,
  setIncidentCreatedAtFilter,
  setIncidentStatusFilter,
  setIncidentDeviceTypeFilter,
  setIncidentPriorityFilter,
  setIncidentsPageSize,
  setIncident,
  setOutstandingIncidentsPageSize,
  setDevice,
  setSOP,
  setSOPNameLikeFilter,
  setSOPFacilityIdFilter,
  setSOPCreatedAtFilter,
  setSOPCreatorIdFilter,
  setSOPPageSize,
  openIncidentDrawer,
  openDeviceDrawer,
  openSOPDrawer,
  closeIncidentDrawer,
  closeDeviceDrawer,
  closeSOPDrawer,
  openSOPIncidentDrawer,
  closeSOPIncidentDrawer,
  toggleSOPIncidentDrawer,
  setSOPIncidentId,
  setSOPIncidentNameLike,
  expandEmergencyCall,
  minimizeEmergencyCall,
  muteEmergencyCall,
  unmuteEmergencyCall,
  toggleMuteEmergencyCall,
  openEmergencyCall,
  closeEmergencyCall,
  setEmergencyCallDisplayIdLikeFilter,
  setEmergencyCallPageSize,
} = uiSlice.actions

export const selectUI = (state: AppState) => state.ui

export const selectSideNavOpen = (state: AppState) => state.ui.sideNavOpen

export const selectEditMode = (state: AppState) => state.ui.editMode

export const selectNotificationsUnread = (state: AppState) =>
  state.ui.notifications.showUnread

export const selectConfirmEditMode = (state: AppState) =>
  state.ui.confirmEditMode

export const selectFacilityFloorsDrawerOpen = (state: AppState) =>
  state.ui.facility.drawerOpen === 'floors'

export const selectFacilityIncidentsDrawerOpen = (state: AppState) =>
  state.ui.facility.drawerOpen === 'incidents'

export const selectFacilityDrawerOpen = (state: AppState) =>
  state.ui.facility.drawerOpen

export const selectFacilityTabBarCount = (state: AppState) => ({
  placedDeviceCount: state.ui.facility.placedDeviceCount,
  incidentsCount: state.ui.facility.incidentsCount,
  floorCount: state.ui.facility.floorCount,
})

export const selectFacilityIdsFilter = (state: AppState) =>
  state.ui.facilities?.filters?.ids

export const selectCreatedAtFilter = (state: AppState) =>
  state.ui.facilities?.filters?.createdAtRange

export const selectFacilityHasIncidentsFilter = (state: AppState) =>
  state.ui.facilities?.filters?.hasIncidents

export const facilityFilterCountSelector = createSelector(
  selectFacilityIdsFilter,
  selectCreatedAtFilter,
  selectFacilityHasIncidentsFilter,
  (idsFilter, createdAtFilter, hasIncidentsFilter) => {
    const ids = idsFilter?.length || 0
    const createdAt = createdAtFilter ? 1 : 0
    const hasIncidents = hasIncidentsFilter ? 1 : 0

    return ids + createdAt + hasIncidents
  }
)

export const selectDeviceFacilityIdsFilter = (state: AppState) =>
  state.ui.devices?.filters?.facilityIds

export const selectDeviceFloorsIdsFilter = (state: AppState) =>
  state.ui.devices?.filters?.floorIds

export const selectDeviceCreatedAtFilter = (state: AppState) =>
  state.ui.devices?.filters?.createdAtRange

export const selectDeviceHasIncidentsFilter = (state: AppState) =>
  state.ui.devices?.filters?.hasIncidents

export const selectDeviceStatusFilter = (state: AppState) =>
  state.ui.devices?.filters?.deviceStatus

export const selectDeviceTypeFilter = (state: AppState) =>
  state.ui.devices?.filters?.deviceType

export const deviceFilterCountSelector = createSelector(
  selectDeviceFacilityIdsFilter,
  selectDeviceFloorsIdsFilter,
  selectDeviceCreatedAtFilter,
  selectDeviceHasIncidentsFilter,
  selectDeviceStatusFilter,
  selectDeviceTypeFilter,
  (
    facilityIdsFilter,
    deviceIdsFilter,
    createdAtFilter,
    hasIncidentsFilter,
    deviceStatusFilter,
    deviceTypeFilter
  ) => {
    const facilityIds = facilityIdsFilter?.length || 0
    const floorIds = deviceIdsFilter?.length || 0
    const createdAt = createdAtFilter ? 1 : 0
    const hasIncidents = hasIncidentsFilter ? 1 : 0
    const deviceStatus = deviceStatusFilter ? 1 : 0
    const deviceType = deviceTypeFilter?.length || 0

    return (
      facilityIds +
      floorIds +
      createdAt +
      hasIncidents +
      deviceStatus +
      deviceType
    )
  }
)

export const selectIncidentNameLikeFilter = (state: AppState) =>
  state.ui.incidents?.filters?.nameLike

export const selectIncidentOwnerIdFilter = (state: AppState) =>
  state.ui.incidents?.filters?.ownerId

export const selectIncidentFacilityIdsFilter = (state: AppState) =>
  state.ui.incidents?.filters?.facilityIds

export const selectIncidentFloorsIdsFilter = (state: AppState) =>
  state.ui.incidents?.filters?.floorIds

export const selectIncidentCreatedAtFilter = (state: AppState) =>
  state.ui.incidents?.filters?.createdAtRange

export const selectIncidentStatusFilter = (state: AppState) =>
  state.ui.incidents?.filters?.incidentsStatus

export const selectIncidentDeviceTypeFilter = (state: AppState) =>
  state.ui.incidents?.filters?.deviceType

export const selectIncidentPriorityFilter = (state: AppState) =>
  state.ui.incidents?.filters?.priorityStatus

export const incidentFilterCountSelector = createSelector(
  selectIncidentOwnerIdFilter,
  selectIncidentFacilityIdsFilter,
  selectIncidentFloorsIdsFilter,
  selectIncidentCreatedAtFilter,
  selectIncidentStatusFilter,
  selectIncidentDeviceTypeFilter,
  selectIncidentPriorityFilter,
  (
    ownerIdFilter,
    facilityIdsFilter,
    floorsIdsFilter,
    createdAtFilter,
    incidentsStatusFilter,
    deviceTypeFilter,
    incidentPriorityFilter
  ) => {
    const ownerId = ownerIdFilter ? 1 : 0
    const facilityIds = facilityIdsFilter?.length || 0
    const floorIds = floorsIdsFilter?.length || 0
    const createdAt = createdAtFilter ? 1 : 0
    const incidentsStatus = incidentsStatusFilter?.length || 0
    const deviceStatus = deviceTypeFilter ? 1 : 0
    const priorityStatus = incidentPriorityFilter?.length || 0

    return (
      ownerId +
      facilityIds +
      floorIds +
      createdAt +
      incidentsStatus +
      deviceStatus +
      priorityStatus
    )
  }
)

export const selectIncidentsPageSize = (state: AppState) =>
  state.ui.incidents?.pageSize

export const selectOutstandingIncidentsPageSize = (state: AppState) =>
  state.ui.outstandingIncidents?.pageSize
export const selectIsIncidentDrawerOpen = (state: AppState) =>
  state.ui.drawers?.incident?.isOpen

export const selectIsDeviceDrawerOpen = (state: AppState) =>
  state.ui.drawers?.device?.isOpen

export const selectIsSOPDrawerOpen = (state: AppState) =>
  state.ui.drawers?.sop?.isOpen

export const selectIncident = (state: AppState) => ({
  id: state.ui.drawers.incident.id,
})
export const selectDevice = (state: AppState) => ({
  id: state.ui.drawers.device.id,
  type: state.ui.drawers.device.type,
})
export const selectSOP = (state: AppState) => ({
  id: state.ui.drawers.sop.id,
})
export const selectSOPNameLikeFilter = (state: AppState) =>
  state.ui.sops?.filters?.nameLike

export const selectSOPFacilityIdFilter = (state: AppState) =>
  state.ui.sops?.filters?.facilityId

export const selectSOPCreatedAtFilter = (state: AppState) =>
  state.ui.sops?.filters?.createdAtRange

export const selectSOPCreatorIdFilter = (state: AppState) =>
  state.ui.sops?.filters?.creatorId

export const selectSOPPageSize = (state: AppState) => state.ui.sops?.pageSize

export const sopFilterCountSelector = createSelector(
  selectSOPFacilityIdFilter,
  selectSOPCreatedAtFilter,
  selectSOPCreatorIdFilter,
  (facilityIdsFilter, createdAtFilter, creatorIdFilter) => {
    const facilityId = facilityIdsFilter ? 1 : 0
    const createdAt = createdAtFilter ? 1 : 0
    const creatorId = creatorIdFilter ? 1 : 0

    return facilityId + createdAt + creatorId
  }
)

export const selectSOPInicdentDrawerState = (state: AppState) =>
  state.ui.drawers?.sopIncident

export const selectEmergencyCall = (state: AppState) => state.ui?.emergencyCall

export const selectEmergencyCallIncident = (state: AppState) =>
  state.ui?.emergencyCall.incident

export const selectEmergencyCallDisplayIdLikeFilter = (state: AppState) =>
  state.ui.emergencyCall?.filters?.displayIdLike

export const selectEmergencyCallPageSize = (state: AppState) =>
  state.ui.emergencyCall?.pageSize

export default uiSlice.reducer
