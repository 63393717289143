import { Role } from '@/features/managed-accounts'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import type { AppState /*, AppThunk*/ } from '../store'

export interface MeState {
  id?: string
  email?: string
  roleName?: Role
  firstName?: string
  lastName?: string
  username?: string
}

const initialState: MeState = null

export const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    setMe: (_, action: PayloadAction<MeState>) => {
      return action.payload
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      console.log('HYDRATE', state, action.payload)
      return {
        ...state,
        ...action.payload.subject,
      }
    },
  },
})

export const { setMe } = meSlice.actions

export const selectMe = (state: AppState) => state.me
export const selectFullName = (state: AppState) =>
  `${state.me?.firstName} ${state.me?.lastName}`
export const selectEmail = (state: AppState) => state.me?.email
export const selectRole = (state: AppState) => state.me?.roleName

export default meSlice.reducer
