import type { KeycloakConfig } from 'keycloak-js'
import { Cookies } from '@react-keycloak/ssr'
import type {
  AuthClientEvent,
  AuthClientError,
  AuthClientTokens,
  AuthClientInitOptions,
} from '@react-keycloak/core'
import { useEffect, useState } from 'react'

import { useAppDispatch } from '@/redux/hooks'
import { setToken as setTokenState } from '@/redux/token/tokenSlice'
import { mixpanel } from '@/utils/analytics'

export default function keycloak() {
  const [token, setToken] = useState('')
  const dispatch = useAppDispatch()

  const initOptions: AuthClientInitOptions = {
    onLoad: '',
  }

  const keycloakConfig: KeycloakConfig = {
    url: process.env.NEXT_PUBLIC_KEYCLOAK_URL,
    clientId: 'public',
    realm: process.env.NEXT_PUBLIC_KEYCLOAK_REALM,
  }

  const persistor = new Cookies()

  const onEvent = async (event: AuthClientEvent, error: AuthClientError) => {
    console.log(`Keycloak Event: ${event}, Error: ${error}`)
    switch (event) {
      case 'onAuthError': {
        break
      }
      case 'onAuthLogout': {
        console.log('Logged out')
        mixpanel.track('Logout')
        break
      }
      case 'onAuthRefreshError': {
        break
      }
      case 'onAuthRefreshSuccess': {
        break
      }
      case 'onAuthSuccess': {
        mixpanel.track('Login', {
          type: 'KC',
          realm: keycloakConfig.realm,
        })
        break
      }
      case 'onInitError': {
        break
      }
      case 'onReady': {
        break
      }
      case 'onTokenExpired': {
        break
      }
      default:
        console.log(`Unknown keycloak event: ${event}`)
        break
    }
  }

  const onTokens = async (tokens: AuthClientTokens) => setToken(tokens.token)

  useEffect(() => {
    if (token) {
      dispatch(setTokenState(token))
    }
  }, [token])

  return {
    initOptions,
    keycloakConfig,
    persistor,
    onEvent,
    onTokens,
  }
}
