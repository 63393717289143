import { useSelector } from 'react-redux'

import {
  selectIncidentOwnerIdFilter,
  selectIncidentFacilityIdsFilter,
  selectIncidentFloorsIdsFilter,
  selectIncidentCreatedAtFilter,
  selectIncidentStatusFilter,
  selectIncidentDeviceTypeFilter,
  selectIncidentPriorityFilter,
  selectIncidentNameLikeFilter,
} from '@/redux/ui/uiSlice'
import { IncidentFilterInput } from '@/graphql/generated/schemas'
import { Filter } from '@/redux/ui/uiSlice.types'

type IncidentFilters =
  | 'nameLike'
  | 'ownerId'
  | 'facilityIdIn'
  | 'floorIdIn'
  | 'createdAtGte'
  | 'deviceTypesContain'
  | 'statusIn'
  | 'priorityIn'

export function useIncidentsFilters(): {
  filters: Pick<IncidentFilterInput, IncidentFilters>
} {
  const nameLikeFilterValue = useSelector(selectIncidentNameLikeFilter)
  const ownerIdFilterValue = useSelector(selectIncidentOwnerIdFilter)
  const facilityIdsFilterValue = useSelector(selectIncidentFacilityIdsFilter)
  const floorsIdsFilterValue = useSelector(selectIncidentFloorsIdsFilter)
  const createdAtFilterValue = useSelector(selectIncidentCreatedAtFilter)
  const incidentStatusFilterValue = useSelector(selectIncidentStatusFilter)
  const incidentDeviceTypeFilterValue = useSelector(
    selectIncidentDeviceTypeFilter
  )
  const incidentPriorityFilterValue = useSelector(selectIncidentPriorityFilter)

  const filters = {
    nameLike: nameLikeFilterValue?.value,
    ownerId: ownerIdFilterValue?.value,
    facilityIdIn: facilityIdsFilterValue?.length
      ? facilityIdsFilterValue?.map((f) => f.value)
      : undefined,
    floorIdIn: floorsIdsFilterValue?.length
      ? floorsIdsFilterValue?.map((f) => f.value)
      : undefined,
    createdAtGte: createdAtFilterValue?.value?.gte,
    deviceTypesContain: incidentDeviceTypeFilterValue?.value,
    statusIn: incidentStatusFilterValue?.length
      ? incidentStatusFilterValue?.map((d) => d.value)
      : undefined,
    priorityIn: incidentPriorityFilterValue?.length
      ? incidentPriorityFilterValue?.map((d) => d.value)
      : undefined,
  }

  return {
    filters: JSON.parse(JSON.stringify(filters)),
  }
}

export const getNameLikeFilter = (filterString: string): Filter =>
  filterString
    ? {
        value: filterString,
        label: 'Incident name contains.',
      }
    : null

export const getOwnerIdFilter = (ownerId: string): Filter => ({
  value: ownerId,
  label: 'Incident owner id.',
})
