import styled from '@emotion/styled'

import { useDisclosure } from '@chakra-ui/react'

import CreateUserModal from './CreateUserModal'
import { RoleExtended } from '../types/types.d'
import CreateTechnicianModal from './CreateTechnicianModal'

const AddAccountButton = styled.button`
  cursor: pointer;
  background-color: green;
  width: 1.85rem;
  height: 1.85rem;
  margin-right: 1.5rem;
  border-radius: 4px;
  color: white;
  font-weight: bolder;
  background-image: linear-gradient(to right, #13b0b0, #46ef9f);
  background-color: #46ef9f;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 65px;
`

const AccountAdd = ({ role }: { role: RoleExtended }) => {
  const {
    isOpen: isCreateAccountOpen,
    onOpen: onCreateAccountOpen,
    onClose: onCreateAccountClose,
  } = useDisclosure()
  return (
    <>
      <AddAccountButton
        onClick={(e) => {
          e.stopPropagation()
          onCreateAccountOpen()
        }}
      >
        &#65291;
      </AddAccountButton>
      {isCreateAccountOpen &&
        (role === RoleExtended.TECHNICIAN ? (
          <CreateTechnicianModal
            isOpen={isCreateAccountOpen}
            onClose={onCreateAccountClose}
          />
        ) : (
          <CreateUserModal
            isOpen={isCreateAccountOpen}
            onClose={onCreateAccountClose}
            role={role}
          />
        ))}
    </>
  )
}

export default AccountAdd
