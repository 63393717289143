import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { CallAvatar } from './CallAvatar'

export const CallEnded = () => {
  return (
    <Box
      display='flex'
      flex={1}
      flexDirection='column'
      alignItems='center'
      p='20px'
      mt='50px'
    >
      <CallAvatar
        title={'911'}
        size={'100px'}
        fontSize={'28px'}
        colour={'#969696'}
        bgColor={'white'}
        border={'1px solid #e2e8f0'}
      />
      <Text mt='20px' color='#282424' fontSize='22px' fontWeight='normal'>
        Call Ended
      </Text>

      <Box
        display='flex'
        direction='row'
        mt='20px'
        mb='20px'
        w='60%'
        h='1px'
        bgColor='#e2e8f0'
      ></Box>
    </Box>
  )
}
