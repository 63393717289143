import { useRef } from 'react'
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Flex,
} from '@chakra-ui/react'
import { HiExclamation } from 'react-icons/hi'

import { Button } from '@/components/ui'
import useReadOnly from '@/hooks/useReadOnly'

interface ConfirmEmergencyCallDialogIProps {
  isOpen: boolean
  onClose: () => void
  handleOnClick: () => void
}

export default function ConfirmEmergencyCallDialog({
  isOpen,
  onClose,
  handleOnClick,
}: ConfirmEmergencyCallDialogIProps) {
  const cancelRef = useRef()
  const { isReadOnly } = useReadOnly()
  return (
    <AlertDialog
      motionPreset='slideInBottom'
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader
          letterSpacing='-0.53px'
          fontSize='22px'
          fontWeight='bold'
        >
          Dialing 911
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Box letterSpacing='-0.53px'>
            This will place an emergency call to the incident's local 911.
          </Box>
          <Flex pt='4' flexDirection='row' alignItems='center' color='#D11D3A'>
            <HiExclamation size='24px' />
            <Box
              fontSize='16px'
              letterSpacing='-0.53px'
              ml='1'
              fontWeight='medium'
            >
              <Box as='span' fontWeight='bold'>
                Warning:
              </Box>{' '}
              This will escalate the incident!
            </Box>
          </Flex>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button variant='secondary' onClick={onClose} mr='3'>
            Cancel
          </Button>
          <Button
            isDisabled={isReadOnly}
            variant='primary'
            onClick={handleOnClick}
          >
            Call 911
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
