import { useSelector } from 'react-redux'

import { Button } from '@/components/ui'
import { BiFilter } from 'react-icons/bi'
import {
  Box,
  Circle,
  Spacer,
  useDisclosure,
  HStack,
  Stack,
} from '@chakra-ui/react'
import { incidentFilterCountSelector } from '@/redux/ui/uiSlice'
import {
  IncidentsFilterDrawer,
  IncidentsOwnerToggle,
} from '../incidents-filter-drawer'
import { SearchInput } from '@/components/ui/SearchInput'

interface IProps {
  filter?: string
  setFilter?: React.Dispatch<React.SetStateAction<string>>
}

export default function IncidentsSearchFilterHeader({
  filter,
  setFilter,
}: IProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const filterCount = useSelector(incidentFilterCountSelector)

  return (
    <>
      <Box
        w='full'
        bgColor='white'
        boxShadow='lg'
        px='6'
        py='4'
        borderBottom='1px solid #D5DCE4'
      >
        <Stack
          maxW='1100px'
          m='auto'
          alignItems='start'
          justifyContent='start'
          direction={{ base: 'column', md: 'row' }}
        >
          <HStack spacing='2'>
            <Box
              w='full'
              maxW='300px'
              display='flex'
              flexDir='row'
              alignItems='center'
            >
              <SearchInput
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                placeholder='Search or Filter'
                dataTestId='incidentsPage_tableHeader_search'
              />
            </Box>
            <IncidentsOwnerToggle />
          </HStack>
          <Spacer />
          <Button
            h='40px'
            px='6'
            leftIcon={
              filterCount > 0 && (
                <Circle
                  h='20px'
                  minW='20px'
                  w='auto'
                  fontSize='11px'
                  bgColor='#fff'
                  color='#353849'
                  data-testid='IncidentsSearchFilterHeader:filterCount'
                >
                  {filterCount}
                </Circle>
              )
            }
            rightIcon={<BiFilter size={24} />}
            onClick={onOpen}
            data-testid='incidentsPage_tableHeader_filterButton'
          >
            Filters
          </Button>
        </Stack>
      </Box>
      <IncidentsFilterDrawer isOpen={isOpen} onClose={onClose} />
    </>
  )
}
