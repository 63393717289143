import { Box, Text } from '@chakra-ui/react'
import { BiCheckCircle } from 'react-icons/bi'
import { HiOutlineExclamationCircle } from 'react-icons/hi'

const EmergencyCallConfigured = () => {
  return (
    <Box px='6' py='3' mb='3'>
      <Box display='flex' flexDir='row' alignItems='center' color='#16B3B0'>
        <BiCheckCircle size='26px' />
        <Text ml='5px' fontWeight='600'>
          911 Emergency calling configured
        </Text>
      </Box>
    </Box>
  )
}

const EmergencyCallEnabled = () => {
  return (
    <Box px='6' py='3' mb='3'>
      <Box display='flex' flexDir='row' alignItems='center' color='black'>
        <HiOutlineExclamationCircle size='26px' />
        <Text ml='5px' fontWeight='600' fontSize='14px'>
          911 Emergency calling enabled. Please contact HiveWatch to configure
        </Text>
      </Box>
    </Box>
  )
}

interface EmergencyCallConfigurationStatusIProps {
  isEnabled: boolean
  isConfigured: boolean
}

export const EmergencyCallConfigurationStatus = ({
  isEnabled,
  isConfigured,
}: EmergencyCallConfigurationStatusIProps) => {
  if (!isEnabled) {
    return null
  }
  if (!isConfigured) {
    return <EmergencyCallEnabled />
  } else {
    return <EmergencyCallConfigured />
  }
}
