import { mixpanel } from '../mixpanel'

import { VideoControlsAction, VideoFeedEvent } from '@/types/video'
import {
  AnalyticsScreen,
  PlayerEvents,
  MixpanelEvents,
} from '../types/playerEvents'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventPayload = any

export const useMixpanel = (screenName?: AnalyticsScreen) => {
  const createFullEventName = (eventName: string): string => {
    const name = `${screenName}:` || ''
    return `${name}${eventName}`
  }

  const trackEvent = (eventName: MixpanelEvents, data?: EventPayload) => {
    const name = createFullEventName(eventName)
    mixpanel.track(name, data)
  }

  const createIncidentVideoEventHandler = (data?: EventPayload) => {
    return (event: VideoFeedEvent) => {
      switch (event) {
        case VideoFeedEvent.BeginRequesting:
          trackEvent(PlayerEvents.requestIncidentClip, data)
          return
        case VideoFeedEvent.Received:
          trackEvent(PlayerEvents.receivedIncidentClip, data)
          return
        case VideoFeedEvent.Playing:
          trackEvent(PlayerEvents.playIncidentClip, data)
          return
        default:
          return
      }
    }
  }

  const createCameraEventHandler = (data?: EventPayload) => {
    return (event: VideoFeedEvent) => {
      switch (event) {
        case VideoFeedEvent.BeginRequesting:
          trackEvent(PlayerEvents.requestLiveFeed, data)
          return
        case VideoFeedEvent.Received:
          trackEvent(PlayerEvents.receivedLiveFeed, data)
          return
        case VideoFeedEvent.Playing:
          trackEvent(PlayerEvents.playLiveFeed, data)
          return
        default:
          return
      }
    }
  }

  const createPlayerActionHandler = (data?: EventPayload) => {
    return (action: VideoControlsAction) => {
      switch (action) {
        case VideoControlsAction.Backward10:
          trackEvent(PlayerEvents.backward10, data)
          return
        case VideoControlsAction.Forward10:
          trackEvent(PlayerEvents.forward10, data)
          return
        case VideoControlsAction.Play:
          trackEvent(PlayerEvents.play, data)
          return
        case VideoControlsAction.Pause:
          trackEvent(PlayerEvents.pause, data)
          return
        case VideoControlsAction.Fullscreen:
          trackEvent(PlayerEvents.fullscreen, data)
          return
        default:
          return
      }
    }
  }

  return {
    trackEvent,
    createPlayerActionHandler,
    createCameraEventHandler,
    createIncidentVideoEventHandler,
  }
}
