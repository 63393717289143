import {
  FormControl as ChakraFormControl,
  FormLabel,
  FormErrorMessage,
  FormControlOptions,
} from '@chakra-ui/react'
import styled from '@emotion/styled'

import Input from '../Input'

export const StyledFormInputControl = styled(ChakraFormControl)`
  .chakra-form__label {
    opacity: 0.5;
    color: #2d2e41;
    font-size: 16px;
  }
`

export interface FormInputControlIProps extends FormControlOptions {
  /**
   * The label text used to inform users as to what information is
   * requested for a text field.
   */
  label?: string
  /**
   * The custom `id` to use for the form control. This is passed directly to the form element (e.g, Input).
   * - The form element (e.g Input) gets the `id`
   * - The form label id: `form-label-${id}`
   * - The form error text id: `form-error-text-${id}`
   * - The form helper text id: `form-helper-text-${id}`
   */
  id?: string
  /**
   * The value for input type.
   */
  type?: string
  /**
   * If `true`, the input will display valid state styles with check icon.
   */
  valid?: boolean
  /**
   * The error message to display when `isInvalid` is `true`.
   */
  errorMessage?: string
  /**
   * The width of the input element.
   */
  width?: string
  /**
   * The `ref` is the `register()` from `react-hook-form`.
   */
  inputRef?: unknown
  /**
   * If added, the button will show an icon to the left of the input.
   */
  leftIcon?: React.ElementType
  /**
   * If `true`, the input will display error state styles.
   */
  isInvalid?: boolean
  /**
   * The placeholder value for input field.
   */
  placeholder?: string
  [key: string]: unknown
}

export default function FormControl({
  id,
  type = 'text',
  label,
  isInvalid = false,
  valid = false,
  errorMessage,
  width = '100%',
  placeholder,
  leftIcon,
  inputRef,
  ...rest
}: FormInputControlIProps) {
  return (
    <StyledFormInputControl
      data-testid='form-control'
      width={width}
      isInvalid={isInvalid}
      id={id}
    >
      <FormLabel data-testid='form-control-label' htmlFor={id} mr='0'>
        {label}
      </FormLabel>
      <Input
        type={type}
        name={id}
        inputRef={inputRef}
        placeholder={placeholder}
        leftIcon={leftIcon}
        valid={valid}
        {...rest}
      />
      <FormErrorMessage data-testid='form-control-error'>
        {errorMessage}
      </FormErrorMessage>
    </StyledFormInputControl>
  )
}
