import React from 'react'
import { Box, Text, Circle, Tooltip } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import {
  openIncidentDrawer,
  selectEmergencyCallIncident,
  selectIsIncidentDrawerOpen,
  setIncident,
} from '@/redux/ui/uiSlice'
import { CallAvatar } from './CallAvatar'
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa'
import { IoCallSharp } from 'react-icons/io5'
import { useCall } from '../hooks/useCall'

export const CallLive = () => {
  const { isMuted, disconnectCall, toggleMuteCall } = useCall()

  const dispatch = useDispatch()
  const isIncidentDrawerOpen = useSelector(selectIsIncidentDrawerOpen)
  const { id, displayId, facilityName } = useSelector(
    selectEmergencyCallIncident
  )

  const handleIncidentDrawer = () => {
    if (!isIncidentDrawerOpen) {
      dispatch(setIncident({ id }))
      dispatch(openIncidentDrawer())
    }
  }

  return (
    <Box
      display='flex'
      flex={1}
      flexDirection='column'
      alignItems='center'
      p='20px'
      mt='50px'
    >
      <CallAvatar title={'911'} size={'100px'} fontSize={'28px'} />
      <Text mt='20px' color='#282424' fontSize='22px' fontWeight='normal'>
        {'Live'}
      </Text>

      <Box
        display='flex'
        direction='row'
        mt='20px'
        mb='20px'
        w='60%'
        h='1px'
        bgColor='#e2e8f0'
      ></Box>

      <Text mt='20px' color='#282424' fontSize='18px' fontWeight='normal'>
        {facilityName}
      </Text>

      <Text
        color='#2E6CDB'
        fontSize='16px'
        fontWeight='normal'
        cursor='pointer'
        data-testid='CallLive:DisplayId'
        onClick={handleIncidentDrawer}
      >
        {displayId}
      </Text>

      <Box
        display='flex'
        direction='row'
        w='410px'
        alignItems={'center'}
        justifyContent='center'
        mt='80px'
      >
        <Tooltip label={isMuted ? 'Unmute' : 'Mute'} placement='top'>
          <Circle
            as='button'
            size='80px'
            border='1px solid #e2e8f0'
            _hover={{
              bg: '#e2e8f0',
            }}
            backgroundColor={isMuted ? '#969696' : 'white'}
            data-testid='CallLive:onMute'
            onClick={toggleMuteCall}
          >
            {isMuted ? (
              <FaMicrophoneSlash size='30px' color={'white'} />
            ) : (
              <FaMicrophone size='30px' opacity={0.6} />
            )}
          </Circle>
        </Tooltip>
        <Tooltip label={'Hangup'} placement='top'>
          <Circle
            as='button'
            size='80px'
            backgroundColor={'red.500'}
            ml='20px'
            _hover={{
              opacity: 0.6,
            }}
            data-testid='CallLive:onDisconnect'
            onClick={disconnectCall}
          >
            <IoCallSharp
              size='40px'
              color='white'
              style={{ transform: 'rotate(135deg)' }}
            />
          </Circle>
        </Tooltip>
      </Box>
    </Box>
  )
}
