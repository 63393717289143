export const getCurrentRangeMessage = (
  pageIndex: number,
  pageSize: number,
  totalItems: number
): string => {
  const rangeStart = pageIndex * pageSize + 1
  if (rangeStart > totalItems || pageIndex < 0) {
    return `${totalItems}`
  }
  const rangeEnd =
    (pageIndex + 1) * pageSize > totalItems
      ? totalItems
      : (pageIndex + 1) * pageSize
  return `${rangeStart} - ${rangeEnd} of ${totalItems}`
}

export const getPageNumbers = (
  currentPage: number,
  pageCount: number
): Array<number | null> => {
  const aroundPages = [currentPage - 1, currentPage, currentPage + 1].filter(
    (i) => i >= 1 && i <= pageCount
  )
  const edgePages = [1, pageCount]
  const allPages = Array.from(new Set([...aroundPages, ...edgePages])).sort(
    (a, b) => a - b
  )
  // add null between not adjacent numbers
  const allPagesWithEllipsis = allPages.map((v, i, arr) =>
    arr[i + 1] === arr[i] + 1 ? [v] : [v, null]
  )
  return allPagesWithEllipsis
    .reduce((prev, current) => [...prev, ...current], [])
    .slice(0, -1)
}
