import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { AppState /*, AppThunk*/ } from '../store'
import { HYDRATE } from 'next-redux-wrapper'

export interface TokenState {
  loggingIn: boolean
  initError: boolean
  token?: string
  idToken?: string
  refreshToken?: string
}

const initialState: TokenState = {
  loggingIn: false,
  initError: false,
  token: null,
}

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    setIdToken: (state, action: PayloadAction<string>) => {
      if (!!action.payload) {
        state.idToken = action.payload
      }
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      if (!!action.payload) {
        state.refreshToken = action.payload
      }
    },
    loggingIn: (state) => {
      state.loggingIn = true
    },
    initError: (state) => {
      state.initError = true
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      console.log('HYDRATE', state, action.payload)
      return {
        ...state,
        ...action.payload.subject,
      }
    },
  },
})

export const { setToken, setIdToken, setRefreshToken, loggingIn, initError } =
  tokenSlice.actions

export const selectToken = (state: AppState) => state.token
export const selectHasToken = (state: AppState) => !!state.token.token
export const selectIsLoggingIn = (state: AppState) => state.token.loggingIn

export default tokenSlice.reducer
