import Router from 'next/router'
import mixpanel from 'mixpanel-browser'
import { getIsDev } from './utils/helpers'

const isDev = getIsDev()

const MIXPANEL_TOKEN =
  process.env.NODE_ENV === 'production' && !isDev
    ? process.env.NEXT_PUBLIC_MIXPANEL_TOKEN
    : process.env.NEXT_PUBLIC_MIXPANEL_DEV_TOKEN

mixpanel.init(MIXPANEL_TOKEN, { debug: isDev })

// Track initial page view
if (typeof window !== 'undefined') {
  // Dont track empty path
  if (window.location?.pathname !== '/') {
    mixpanel.track(window.location?.pathname)
  }
}

Router.events.on('routeChangeComplete', (url) => {
  if (url !== '/') {
    mixpanel.track(url)
  }
})

export { mixpanel }
