import { Circle, Icon as ChakraIcon } from '@chakra-ui/react'
import styled from '@emotion/styled'

export interface PinMarkerIProps {
  /**
   * The size of the pin marker element.
   */
  size?: string
  /**
   * The size of the pin marker border.
   */
  borderSize?: string
  /**
   * The color of the pin marker border.
   */
  borderColor?: string
  /**
   * The background gradient of the pin marker.
   */
  bgGradient?: string
  /**
   * The color of the pin marker icon.
   */
  color?: string
  /**
   * The icon that will be displayed inside of the pin marker.
   */
  Icon: React.ElementType
  /**
   * The `RGBA` value of the pin marker pulse box shadow i.e. (rgba(43, 210, 180, 0.4)).
   */
  pulseBoxShadow?: string
  /**
   * The `RGBA` value of the pin marker pulse transparent box shadow i.e. (rgba(43, 210, 180, 0)).
   */
  fadedPulseBoxShadow?: string
  bgColor?: string
}

const StyledPinMarker = styled(Circle)`
  box-shadow: 0 0 0 ${(p) => p.pulseBoxShadow};
  animation: pulse 2s infinite;
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 ${(p) => p.pulseBoxShadow};
    }
    70% {
      box-shadow: 0 0 0 18px ${(p) => p.fadedPulseBoxShadow};
    }
    100% {
      box-shadow: 0 0 0 0 ${(p) => p.fadedPulseBoxShadow};
    }
  }
`

export default function PinMaker({
  size = '36px',
  bgColor = '#fff',
  borderColor = '#4ad06c',
  color = '#000',
  Icon,
  pulseBoxShadow = 'rgba(43, 210, 180, 0.4)',
  fadedPulseBoxShadow = 'rgba(43, 210, 180, 0)',
}: PinMarkerIProps) {
  return (
    <StyledPinMarker
      size={size}
      bgColor={bgColor}
      color={color}
      pulseBoxShadow={pulseBoxShadow}
      fadedPulseBoxShadow={fadedPulseBoxShadow}
      _after={{
        content: '""',
        pos: 'absolute',
        borderRadius: '50%',
        h: 'calc(100% - 4px)',
        w: 'calc(100% - 4px)',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderColor: borderColor,
        borderWidth: '1px',
        borderStyle: 'solid',
      }}
    >
      <ChakraIcon boxSize={5} as={Icon} color={color} />
    </StyledPinMarker>
  )
}
