import { Box, CloseButton, Spacer } from '@chakra-ui/react'

function ErrorToast({
  value,
  onClose,
}: {
  value: string
  onClose?: () => void
}) {
  return (
    <Box
      data-testid='ErrorToast'
      boxShadow='3px 3px 5px 6px rgba(0, 0, 0, 0.2)'
      py='3'
      px='4'
      bg='#fff'
      mt='20px'
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      borderRadius='4px'
    >
      <Box as='span' mr={4}>
        ❌
      </Box>
      <Box
        as='span'
        letterSpacing='-0.4px'
        fontWeight='bold'
        color='#353849'
      >{`${value}`}</Box>
      <Spacer />
      <CloseButton onClick={onClose} ml={4} />
    </Box>
  )
}

export default ErrorToast
