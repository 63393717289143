import { InputGroup, InputLeftElement, Input } from '@chakra-ui/react'
import { ChangeEvent } from 'react'
import { BiSearch } from 'react-icons/bi'

interface IProps {
  placeholder?: string
  value?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  dataTestId?: string
}

export default function SearchInput({
  placeholder,
  value,
  onChange,
  dataTestId,
}: IProps) {
  return (
    <InputGroup>
      <InputLeftElement
        pointerEvents='none'
        fontSize='1.2em'
        children={<BiSearch />}
      />
      <Input
        data-testid={dataTestId}
        variant='outline'
        autoComplete='off'
        autoCorrect='off'
        autoCapitalize='off'
        borderWidth='1px'
        borderColor='#D5DCE4'
        bgColor='white'
        fontWeight='medium'
        _placeholder={{
          fontSize: '14px',
        }}
        color='#353849'
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </InputGroup>
  )
}
