import { ChangeEvent, useState, useEffect } from 'react'

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'

import {
  Icon,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input as ChakraInput,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { HiOutlineCheckCircle, HiOutlineLocationMarker } from 'react-icons/hi'

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox'
import '@reach/combobox/styles.css'
import { AddressSearchError } from './AddressSearchError'

const StyledInputGroup = styled(InputGroup)`
  .chakra-input {
    padding-left: 3rem;
    padding-right: ${(p) => (p['data-valid'] ? '3rem' : '1rem')};
    font-weight: 600;
    font-size: 16px;
    color: #000;

    ::placeholder {
      font-size: 14px;
      color: #2d2e41;
      opacity: 30%;
      font-style: italic;
      font-weight: 500;
    }
  }
`

interface Address {
  value: string
  latitude?: number
  longitude?: number
}

export interface AddressSearchInputIProps {
  onSelectAddress?: (address?: Address) => void
  defaultValue?: string
  isSubmitting?: boolean
  disabled?: boolean
}

export const AddressSearchInput = ({
  onSelectAddress,
  defaultValue,
  isSubmitting,
  disabled,
}: AddressSearchInputIProps) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
    defaultValue,
  })
  const [isDirty, setIsDirty] = useState(false)
  const [isError, setIsError] = useState(true)

  useEffect(() => {
    setValue(defaultValue, false)
  }, [defaultValue])

  useEffect(() => {
    isSubmitting && handleOnBlur()
  }, [isSubmitting])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    // Whenever input is changed, address is assumed to be incorrect
    setValue(e.target.value)
    onSelectAddress?.(undefined)
    setIsError(true)
  }

  const handleSelect = async (address: string) => {
    // Whenever address is selected from suggestions, it is assumed to be correct
    setValue(address, false)
    clearSuggestions()
    try {
      const results = await getGeocode({ address })
      const { lat, lng } = await getLatLng(results[0])
      onSelectAddress?.({ value: address, latitude: lat, longitude: lng })
      setIsError(false)
    } catch {}
  }

  const handleOnBlur = () => {
    setIsDirty(true)
  }

  return (
    <Combobox onSelect={handleSelect}>
      <StyledInputGroup
        size='lg'
        width='100%'
        data-valid={!isError && !isDirty}
      >
        <InputLeftElement
          children={
            <Icon boxSize={6} color='#4578D3' as={HiOutlineLocationMarker} />
          }
        />
        <ComboboxInput
          as={ChakraInput}
          variant='outline'
          autoComplete='off'
          autoCorrect='off'
          autoCapitalize='off'
          spellCheck='false'
          borderColor='#9FB8E5'
          errorBorderColor='#D01030'
          isInvalid={isError && isDirty}
          borderWidth='2px'
          bgColor='white'
          placeholder='Start typing your address'
          value={value}
          onBlur={handleOnBlur}
          onChange={handleChange}
          isDisabled={!ready || disabled}
        />
        {!isError && (
          <InputRightElement data-testid='input-valid-icon'>
            <Icon
              boxSize='42px'
              color='#2BD2B4'
              as={HiOutlineCheckCircle}
              bgColor='white'
              px='7px'
            />
          </InputRightElement>
        )}
      </StyledInputGroup>
      {status === 'OK' && (
        <ComboboxPopover portal={false}>
          <ComboboxList>
            {data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
          </ComboboxList>
        </ComboboxPopover>
      )}
      {isError && isDirty && <AddressSearchError />}
    </Combobox>
  )
}
