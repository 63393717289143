import { useState, useRef, useEffect } from 'react'

const padStart = (num: number) => {
  return num.toString().padStart(2, '0')
}

const formatMs = (milliseconds: number) => {
  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)

  // using the modulus operator gets the remainder if the time roles over
  // we don't do this for hours because we want them to rollover
  // seconds = 81 -> minutes = 1, seconds = 21.
  // 60 minutes in an hour, 60 seconds in a minute, 1000 milliseconds in a second.
  minutes = minutes % 60
  seconds = seconds % 60

  let str = `${padStart(minutes)}:${padStart(seconds)}`

  if (hours > 0) {
    str = `${padStart(hours)}:${str}`
  }

  return str
}

export const useStopWatch = () => {
  const [time, setTime] = useState(0)
  const [isRunning, setIsRunning] = useState(false)
  const [startTime, setStartTime] = useState<number>(0)
  const interval = useRef<ReturnType<typeof setInterval>>()
  const startTimeRef = useRef<Date>(null)

  useEffect(() => {
    if (startTime > 0) {
      interval.current = setInterval(() => {
        setTime(() => Date.now() - startTime)
      }, 1000)
    } else {
      if (interval.current) {
        clearInterval(interval.current)
        interval.current = undefined
      }
    }
  }, [startTime])

  const start = () => {
    setIsRunning(true)
    setStartTime(Date.now())
    startTimeRef.current = new Date()
  }

  const stop = () => {
    setIsRunning(false)
    startTimeRef.current = null
    setStartTime(0)
  }

  const reset = () => {
    setIsRunning(false)
    startTimeRef.current = null
    setStartTime(0)
    setTime(0)
  }

  return {
    start,
    stop,
    reset,
    startTimeRef,
    isRunning,
    time: formatMs(time),
  }
}
