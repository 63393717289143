import { WebMercatorViewport } from 'react-map-gl'
import { ViewportState, Bounds, Point } from '../types/types'

export const calculateBoundingViewport = (
  points: Point[],
  width: number,
  height: number,
  padding = 50
): ViewportState => {
  const pointsLong = points.map((point) => point[0])
  const pointsLat = points.map((point) => point[1])

  const cornersLongLat = [
    [Math.min(...pointsLong), Math.min(...pointsLat)],
    [Math.max(...pointsLong), Math.max(...pointsLat)],
  ] as Bounds

  // use WebMercatorViewport to get center longitude/latitude and zoom
  const { longitude, latitude, zoom } = new WebMercatorViewport({
    width,
    height,
  }).fitBounds(cornersLongLat, { padding })

  // adjust zoom in case there is only one facility
  const adjustedZoom = points.length > 1 ? zoom : 10

  return { longitude, latitude, zoom: adjustedZoom }
}

export const defaultViewport: ViewportState = {
  latitude: 0,
  longitude: 0,
  zoom: 1,
}

export const areViewportsEqual = (vp1: ViewportState, vp2: ViewportState) =>
  vp1.latitude === vp2.latitude &&
  vp1.longitude === vp2.longitude &&
  vp1.zoom === vp2.zoom

export const isDefaultViewport = (vp: ViewportState) =>
  areViewportsEqual(vp, defaultViewport)
