import { MigrationManifest } from 'redux-persist/es/types'
import { AppState } from './store'
import { DeviceType } from '@/graphql/generated/schemas'

export const migrations: MigrationManifest = {
  0: (state: AppState) => state,
  1: (state: AppState) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        incidents: {
          ...state.ui.incidents,
          pageSize: 25,
        },
      },
    }
  },
  2: (state: AppState) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        sops: {
          ...state.ui.sops,
          filters: {
            nameLike: null,
            facilityId: null,
            createdAtRange: null,
            creatorId: null,
          },
          pageSize: 25,
        },
      },
    }
  },
  3: (state: AppState) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        drawers: {
          incident: {
            id: null,
            isOpen: false,
          },
          device: {
            id: null,
            deviceType: DeviceType.Camera,
            isOpen: false,
          },
          sop: {
            id: null,
            isOpen: false,
          },
        },
      },
    }
  },
  4: (state: AppState) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        notifications: {
          showUnread: false,
        },
        drawers: {
          ...state.ui?.drawers,
          sopIncident: {
            id: null,
            isOpen: false,
            sopNameLike: null,
          },
        },
      },
    }
  },
  5: (state: AppState) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        emergencyCall: {
          isOpen: false,
          isExpanded: true,
          isMuted: false,
          incident: {
            id: null,
            displayId: null,
            facilityName: null,
          },
        },
      },
    }
  },
}
