import { Box } from '@chakra-ui/react'
import { StyledSelect } from '@/components/ui/InputSelect/InputSelect'
import styled from '@emotion/styled'

const pageSizeOptions = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
]

// Overwrite some of the styles
const PageSizeSelect = styled(StyledSelect)`
  .react-select__control {
    min-height: 0px;
  }
  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    padding: 5px 3px;
    padding-left: 0;
  }
  .react-select__single-value {
    overflow: visible;
    padding: 0px;
  }
`
interface PageSizeSelectProps {
  title: string
  pageSize: number
  setPageSize: (newPageSize: number) => void
  onClick: (value: number) => void
  dataTestId?: string
}

export const PageSizeSelector = ({
  title,
  pageSize,
  setPageSize,
  onClick,
  dataTestId,
}: PageSizeSelectProps) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='flex-end'
      data-testid={dataTestId}
    >
      <Box as='span' mr='15px'>
        {title}
      </Box>
      <Box width='70px'>
        <PageSizeSelect
          data-testid='PageSizeSelector:select'
          classNamePrefix='react-select'
          isSearchable={false}
          menuPlacement='top'
          defaultValue={pageSizeOptions.filter((o) => o.value === pageSize)}
          options={pageSizeOptions}
          onChange={(e: { value: number }) => {
            setPageSize(e.value)
            onClick(e.value)
          }}
          aria-label='select page size'
        />
      </Box>
    </Box>
  )
}
