import { gql } from '@apollo/client'
import { Maybe } from '@/graphql/generated/schemas'
import { Notification } from '../types'

export type OnCreateNotificationSubscription = {
  __typename?: 'Subscription'
  notification?: Maybe<Notification>
}

export const ON_CREATE_NOTIFICATION = gql`
  subscription OnCreateNotification($userId: ID!) {
    notification: onCreateNotification(userId: $userId) {
      userId
      userRole
      notificationTimestamp
      notificationType
      incidentId
      incidentDisplayId
      incidentName
      incidentDescription
      incidentType
      incidentSourceName
      facilityId
      facilityShortName
      incidentActionUserName
      incidentActionUserRole
      audioMessageId
      audioMessageUserName
      audioMessageUserRole
    }
  }
`
