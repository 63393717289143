export const createId = () => {
  return Math.random().toString(36).substring(7)
}

export function uuid() {
  return (performance.now().toString(36) + Math.random().toString(36)).replace(
    /\./g,
    ''
  )
}

export function getMajorMinorVersion(version: string): string {
  const isMajorMinorPatchVersion = version?.split('.').length - 1 === 2
  if (isMajorMinorPatchVersion) {
    return version?.substring(0, version?.lastIndexOf('.'))
  }
  return version
}
