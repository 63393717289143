import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { FaMicrophoneSlash } from 'react-icons/fa'

const Separator = () => (
  <Box
    width='1px'
    backgroundColor='#2D2E41'
    height='0.85em'
    mx={3}
    opacity={0.5}
  ></Box>
)

interface CallHeaderIProps {
  title: string
  subtitle: string
  duration?: string
  isMuted?: boolean
}

export const CallHeader = ({
  title,
  subtitle,
  duration,
  isMuted,
}: CallHeaderIProps) => {
  return (
    <Flex direction='column' ml='20px' mr='20px'>
      <Text color='#282424' fontSize='16px' fontWeight='normal'>
        {title}
      </Text>

      <Flex color='#2D2E41' fontSize='13px' opacity={0.5} alignItems='center'>
        <Text noOfLines={1}>{subtitle}</Text>
        {duration && (
          <>
            <Separator />
            <Text>{duration}</Text>
          </>
        )}
        <Flex ml='20px'>
          {isMuted && <FaMicrophoneSlash size='20px' opacity={0.6} />}
        </Flex>
      </Flex>
    </Flex>
  )
}
