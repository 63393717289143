export function removeUnderscoreAndCapitalize(value: string) {
  return value
    ?.replace(/_/g, ' ')
    .toLowerCase()
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
}

export function formatTimeInterval(value: number): string {
  const SECONDS_IN_MINUTE = 60
  if (value < SECONDS_IN_MINUTE) {
    return `${value}s`
  }

  const minutes = Math.floor(value / SECONDS_IN_MINUTE)
  const seconds = value % SECONDS_IN_MINUTE
  return `${minutes}m ${seconds}s`
}

export const formatBytes = (bytes: number) => {
  if (bytes == 0) {
    return '0.00 B'
  }
  const newBytes = Math.floor(Math.log(bytes) / Math.log(1024))
  const newBytesStr = (bytes / Math.pow(1024, newBytes)).toFixed(2)
  const suffix = ' kMGTP'.charAt(newBytes) + 'B'
  return `${newBytesStr} ${suffix.trimStart()}`
}
