import { Box, HStack, Spinner } from '@chakra-ui/react'
import { motion, AnimatePresence } from 'framer-motion'

export function PageSpinnerToast({
  loading,
  label = 'Loading',
  showLabel = false,
}: {
  loading: boolean
  label?: string
  showLabel?: boolean
}) {
  return (
    <AnimatePresence>
      {loading && (
        <HStack
          as={motion.div}
          spacing={4}
          initial={{ y: -100, x: '-50%', opacity: 0 }}
          exit={{ y: -100, x: '-50%', opacity: 0 }}
          animate={{ y: 50, x: '-50%', opacity: 1 }}
          zIndex='11'
          bgColor='white'
          pos='absolute'
          left='50%'
          rounded='10px'
          px='6'
          py='2'
          boxShadow='0px 2px 5px 6px rgba(0, 0, 0, 0.2)'
        >
          {showLabel && (
            <Box fontWeight='semibold' letterSpacing='-0.7px'>
              {label}
            </Box>
          )}
          <Spinner
            thickness='4px'
            emptyColor='gray.300'
            color='#298bbd'
            size='md'
          />
        </HStack>
      )}
    </AnimatePresence>
  )
}
