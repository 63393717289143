import React from 'react'
import { Box, Flex, Tooltip } from '@chakra-ui/react'
import { CgExpand } from 'react-icons/cg'

import { CallAvatar } from './CallAvatar'
import { CallHeader } from './CallHeader'
import { useCall } from '../hooks/useCall'

export const CallMinimized = () => {
  const { isMuted, callDuration, expandCall } = useCall()

  return (
    <Box display='flex' direction='row' w='410px' alignItems={'center'}>
      <CallAvatar title={'911'} size={'60px'} fontSize={'18px'} />
      <CallHeader
        title={'Local Law Enforcement'}
        subtitle={'Live'}
        duration={callDuration}
        isMuted={isMuted}
      />
      <Flex justifyContent={'flex-end'} flex={1}>
        <Tooltip label={'Expand'} placement='top'>
          <Box
            cursor='pointer'
            data-testid='CallMinimized:button'
            onClick={expandCall}
            opacity={0.3}
            _hover={{
              opacity: 1.0,
            }}
          >
            <CgExpand size={24} data-testid='CallMinimized:icon' />
          </Box>
        </Tooltip>
      </Flex>
    </Box>
  )
}
