import { useRef } from 'react'
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Flex,
} from '@chakra-ui/react'
import { HiExclamation, HiOutlineTrash } from 'react-icons/hi'

import { Button } from '@/components/ui'
import useReadOnly from '@/hooks/useReadOnly'

interface IProps {
  isOpen: boolean
  loading?: boolean
  onClose: () => void
  title: string | JSX.Element
  handleOnClick: () => void
  itemTitle: string
}

export default function ConfirmRemovalDialog({
  isOpen,
  loading,
  onClose,
  title,
  itemTitle,
  handleOnClick,
}: IProps) {
  const cancelRef = useRef()
  const { isReadOnly } = useReadOnly()
  return (
    <AlertDialog
      motionPreset='slideInBottom'
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay data-testid='ConfirmRemovalDialog:overlay' />

      <AlertDialogContent>
        <AlertDialogHeader
          letterSpacing='-0.53px'
          fontSize='22px'
          fontWeight='bold'
        >
          {title}
        </AlertDialogHeader>
        <AlertDialogCloseButton data-testid='ConfirmRemovalDialog:close' />
        <AlertDialogBody>
          <Box
            letterSpacing='-0.53px'
            data-testid='ConfirmRemovalDialog:question'
          >
            {'Are you sure you want to remove '}
            <Box as='span' fontWeight='bold'>
              {itemTitle}
            </Box>
            ?
          </Box>
          <Flex pt='4' flexDirection='row' alignItems='center' color='#D11D3A'>
            <HiExclamation size='24px' />
            <Box
              fontSize='16px'
              letterSpacing='-0.53px'
              ml='1'
              fontWeight='medium'
            >
              <Box as='span' fontWeight='bold'>
                Warning:
              </Box>{' '}
              this action cannot be undone!
            </Box>
          </Flex>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            variant='secondary'
            onClick={onClose}
            mr='3'
            data-testid='ConfirmRemovalDialog:cancel'
          >
            Cancel
          </Button>
          <Button
            isDisabled={loading || isReadOnly}
            isLoading={loading}
            leftIcon={<HiOutlineTrash size='24px' />}
            variant='danger'
            onClick={handleOnClick}
            data-testid='ConfirmRemovalDialog:confirm'
          >
            Confirm Removal
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
