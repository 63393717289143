import {
  useEffect,
  createContext,
  useContext,
  ReactNode,
  useState,
} from 'react'
import { ApolloError } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import { useKeycloak } from '@react-keycloak/ssr'
import type { KeycloakInstance } from 'keycloak-js'
import { useMeQuery } from '@/graphql/generated/hooks'
import { MeState, setMe, selectMe } from '@/redux/me/meSlice'
import { setToken, selectHasToken } from '@/redux/token/tokenSlice'
import { mixpanel } from '@/utils/analytics'
import { resetUi } from '@/redux/ui/uiSlice'
import { Role, DEFAULT_ROLE } from '@/features/managed-accounts'
import { setNotifications } from '@/redux/notifications/notificationsSlice'

interface IUseAuth {
  me: MeState
  loading: boolean
  loggedIn: boolean
  logIn: () => void
  logOut: () => void
  error: ApolloError
  errorMessage?: string
  startPolling: (pollInterval: number) => void
  stopPolling: () => void
}

export const AuthContext = createContext(null)

export function AuthProvider({ children }: { children: ReactNode }) {
  const auth = useAuthProvider()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}
const useAuth = (): IUseAuth => useContext(AuthContext)

function useAuthProvider(): IUseAuth {
  const { keycloak } = useKeycloak<KeycloakInstance>()
  const dispatch = useDispatch()
  const me = useSelector(selectMe)
  const token = useSelector(selectHasToken)
  const [errorMessage, setErrorMessage] = useState<string>(null)
  const { data, loading, error, client, startPolling, stopPolling } =
    useMeQuery({
      fetchPolicy: 'network-only',
    })
  const user = data?.me

  const allowedRoles = [
    Role.OPERATOR,
    Role.PROGRAM_MANAGER,
    Role.READ_ONLY_USER,
    DEFAULT_ROLE,
  ]
  const loggedIn = Boolean(
    user && token && allowedRoles.includes(user?.roleName as Role)
  )

  const logIn = async () => {
    try {
      window.localStorage.removeItem('viewport')
      await keycloak.login()
    } catch (error) {
      console.log(error)
    }
  }

  const logOut = async () => {
    try {
      dispatch(resetUi())
      dispatch(setToken(null))
      dispatch(setMe(false))
      dispatch(setNotifications([]))
      await keycloak.logout({ redirectUri: window.location.origin + '/' })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!loading && loggedIn) {
      mixpanel.track('Login', {
        realm: keycloak.realm,
        type: 'GQL',
        firstName: user?.firstName,
        lastName: user?.lastName,
        role: user?.roleName,
        userName: user?.email,
        email: user?.email,
        userId: user?.id,
      })
      dispatch(setMe(user))
      client.resetStore()
    } else if (!loading && !loggedIn) {
      dispatch(setMe(false))
    }
  }, [loggedIn, loading])

  useEffect(() => {
    if (data && data.me) {
      const { roleName } = data.me
      if (!allowedRoles.includes(roleName as Role)) {
        setErrorMessage('User does not have the required permission')
      } else {
        setErrorMessage(null)
      }
    }
  }, [data, user])

  return {
    me,
    loading,
    loggedIn,
    error,
    errorMessage,
    logIn,
    logOut,
    startPolling,
    stopPolling,
  }
}

export default useAuth
